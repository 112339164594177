@import url('https://fonts.googleapis.com/css?family=Merriweather|Pacifico');

.top-nav{
    top: 0;
    position: absolute;
    cursor: default;
    background-color: #f2f2f2;
    overflow: hidden;
    z-index: 10;
    width: 100%;
    border-bottom: .2rem solid lightblue;
}

.left-nav {
    float: left;
    color: #333;
    text-align: left;
    padding: .875rem 1rem;
    text-decoration: none;
    font-size: 1.0625rem;
    font-family: 'Pacifico', 'Times New Roman', Times, serif;
}