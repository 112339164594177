@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

.resume {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.resume-image {
    max-width: 35.5rem;
}

.download-link {
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
    font-weight: bold;
    color: lightblue;
}

.download-link:hover {
    color: lightgray;
}

@media only screen and (max-width: 37.5rem) {
    .resume {
        max-width: 100%;
        margin-top: 3.7rem;
        overflow: scroll;
    }
}