
.landing-page{
    height: 100vh;
    width: 100vw;
    margin: auto;
    display: block;
    position: relative;
    overflow: hidden;
    z-index: 0;
}
.landing-image{
    width: 100%;
    height: 100%;
}
.overlay{
    position: absolute;
    background-color: rgb(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
}