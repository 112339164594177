@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

.home {
    z-index: 2;
    position: fixed;
    background-color: white;
    width: 60rem;
    max-height: 100%;
    max-width: 100%;
    border: .2rem solid lightblue;
    border-radius: 1rem;
    padding: 1.5rem;
}

.profile-icon {
    position: relative;
    float: left;
    border-radius: 5rem;
    border: .1rem solid lightblue;
    width: 10%;
}

.description {
    display: flex;
    padding-top: 1rem;
    padding-left: 1rem;
    text-align: left;
    font-weight: bold;
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
}

@media only screen and (max-width: 37.5rem) {
    .home {
        top: 0;
        margin-top: 5rem;
    }
}