@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

.tech-content {
    z-index: 2;
    position: fixed;
    background-color: white;
    width: 60rem;
    max-height: 100%;
    max-width: 100%;
    border: .2rem solid lightblue;
    border-radius: 1rem;
    padding: 1.5rem;
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
    display: flex;
    flex-flow: row;
}

.subject {
    font-size: 2rem;
    font-weight: bold;
    background: lightblue;
}

.tech-row {
    width: 100%;
    display: flex;
    flex-flow: column;
}

.tech-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin: 1rem;
    padding: .2rem;
    font-size: 1.5rem;
}

.tech-card:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}