@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

.work-history{
    z-index: 2;
    position: fixed;
    display:flex;
    flex-flow: column;
    padding: 2rem;
    border: .2rem solid lightblue;
    background-color: white;
    border-radius: 1rem;
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
    font-size: 1.2rem;
}

.work-content{
    text-align: left;
}

.anchor-link{
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3rem;
}

.anchor-link:hover{
    color: lightgray;
}

.orange{
    color: darkorange;
}

.blue{
    color: rgb(43, 43, 228);
}