@import url(https://fonts.googleapis.com/css?family=Merriweather|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Merriweather|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  max-height: 100vh;
}


.landing-page{
    height: 100vh;
    width: 100vw;
    margin: auto;
    display: block;
    position: relative;
    overflow: hidden;
    z-index: 0;
}
.landing-image{
    width: 100%;
    height: 100%;
}
.overlay{
    position: absolute;
    background-color: rgb(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
}
.top-nav{
    top: 0;
    position: absolute;
    cursor: default;
    background-color: #f2f2f2;
    overflow: hidden;
    z-index: 10;
    width: 100%;
    border-bottom: .2rem solid lightblue;
}

.left-nav {
    float: left;
    color: #333;
    text-align: left;
    padding: .875rem 1rem;
    text-decoration: none;
    font-size: 1.0625rem;
    font-family: 'Pacifico', 'Times New Roman', Times, serif;
}
.nav-menu {
    float: right;
    color: #333;
    text-align: center;
    padding: 1.15rem 1rem;
    text-decoration: none;
    font-size: 1.0625rem;
    font-family: 'Merriweather';
    padding: .875rem 1rem;
    margin-right: 5rem;
}

.hover-override:hover {
    background-color: none !important;
}

.dropdown-icon {
    stroke: black;
    z-index: 500;
}
.resume {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.resume-image {
    max-width: 35.5rem;
}

.download-link {
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
    font-weight: bold;
    color: lightblue;
}

.download-link:hover {
    color: lightgray;
}

@media only screen and (max-width: 37.5rem) {
    .resume {
        max-width: 100%;
        margin-top: 3.7rem;
        overflow: scroll;
    }
}
.home {
    z-index: 2;
    position: fixed;
    background-color: white;
    width: 60rem;
    max-height: 100%;
    max-width: 100%;
    border: .2rem solid lightblue;
    border-radius: 1rem;
    padding: 1.5rem;
}

.profile-icon {
    position: relative;
    float: left;
    border-radius: 5rem;
    border: .1rem solid lightblue;
    width: 10%;
}

.description {
    display: flex;
    padding-top: 1rem;
    padding-left: 1rem;
    text-align: left;
    font-weight: bold;
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
}

@media only screen and (max-width: 37.5rem) {
    .home {
        top: 0;
        margin-top: 5rem;
    }
}
.work-history{
    z-index: 2;
    position: fixed;
    display:flex;
    flex-flow: column;
    padding: 2rem;
    border: .2rem solid lightblue;
    background-color: white;
    border-radius: 1rem;
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
    font-size: 1.2rem;
}

.work-content{
    text-align: left;
}

.anchor-link{
    text-decoration: none;
    font-weight: bold;
    font-size: 1.3rem;
}

.anchor-link:hover{
    color: lightgray;
}

.orange{
    color: darkorange;
}

.blue{
    color: rgb(43, 43, 228);
}
.tech-content {
    z-index: 2;
    position: fixed;
    background-color: white;
    width: 60rem;
    max-height: 100%;
    max-width: 100%;
    border: .2rem solid lightblue;
    border-radius: 1rem;
    padding: 1.5rem;
    font-family: 'Playfair Display', 'Times New Roman', Times, serif;
    display: flex;
    flex-flow: row;
}

.subject {
    font-size: 2rem;
    font-weight: bold;
    background: lightblue;
}

.tech-row {
    width: 100%;
    display: flex;
    flex-flow: column;
}

.tech-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin: 1rem;
    padding: .2rem;
    font-size: 1.5rem;
}

.tech-card:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
html, body {
  height: 100%;
    margin: 0;
    padding: 0;
}

